import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const SIMPLE_LEADFORM_LOCALES = {
	WOLTAIR_CZ: null,
	WOLTAIR_PL: null,
	WOLTAIR_DE: 'komplettpaket',
	WOLTAIR_IT: null
}

export const SIMPLE_LEADFORM = SIMPLE_LEADFORM_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return SIMPLE_LEADFORM === param
}) satisfies ParamMatcher
