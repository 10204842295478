import { CaptureConsole } from '@sentry/integrations'
import * as Sentry from '@sentry/sveltekit'
import { initLogger } from '@woltair/logger-fe'
import {
	SentryClientConfig,
	failedDynamicModuleImport,
	handleErrorWithSentry
} from '@woltair/sentry-fe'
import Smartlook from 'smartlook-client'
import { version } from '$app/environment'
import { SMARTLOOK_ID } from '$lib/consts'
import type { HandleClientError } from '@sveltejs/kit'
import type { HandleErrorInput } from '@woltair/sentry-fe'
import { PUBLIC_ENVIRONMENT } from '$env/static/public'
// @ts-expect-error - Sentry is not typed
window.Sentry = Sentry

Sentry.init(
	SentryClientConfig({
		dsn: 'https://11fc607e4871425ea2ddf42c664d420b@o479104.ingest.sentry.io/4504361136881664',
		release: version,
		environment: PUBLIC_ENVIRONMENT,
		integrations: [
			new Sentry.Integrations.Breadcrumbs(),
			new Sentry.BrowserTracing(),
			new CaptureConsole({
				levels: ['error']
			})
		]
	})
)

initLogger({
	environment: PUBLIC_ENVIRONMENT,
	service: 'woltair-web',
	version: version
})

const beforeCaptureException = (input: HandleErrorInput) => {
	if (failedDynamicModuleImport(input.error as Error)) {
		logger.warn(
			'beforeCaptureException',
			`Failed to load dynamically imported module. Reloading page "${window.location.href}".`
		)
		window.location.reload()
	}
}

const webHandleError: HandleClientError = (input) => {
	logger.error(
		`unhandled.clientError`,
		`routeId - ${input.event.route.id}`,
		input.error,
		input.event
	)

	return {
		code: 500,
		message: `Vypadá, že došlo u nás k chybě, omlouváme se. Tlačítky níže se můžete vrátit na hlavní stránku nebo nám rovnou zavolejte.`
	}
}

export const handleError = handleErrorWithSentry(beforeCaptureException, webHandleError)

if (SMARTLOOK_ID && PUBLIC_ENVIRONMENT !== 'local') Smartlook.init(SMARTLOOK_ID, { region: 'eu' })
