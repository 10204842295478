import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143')
];

export const server_loads = [0,32,10,30,31,12,11,8,7,6,5,24,23,16,14,28];

export const dictionary = {
		"/(default)/(public)": [~35,[3]],
		"/account-fe/[...rest]": [140,[32]],
		"/healtz": [141],
		"/(default)/(public)/(features-support)/legacy_expert_advice/[[paging=paging]]": [~75,[3,10]],
		"/(default)/(public)/(features-support)/legacy_expert_advice/[category_or_slug]/[[paging=paging]]": [~76,[3,10]],
		"/(default)/(public)/(features-support)/legacy_expert_advice/[category_or_slug]/[slug]": [~77],
		"/(default)/(public)/(statics-support)/(cs)/nasim-obchodnikum-muzete-verit": [~130,[3,30]],
		"/(default)/(public)/(statics-support)/(cs)/nova-zelena-usporam": [~131,[3,30,31]],
		"/(default)/(public)/(features-core)/(agreements)/potvrzeni-souhlasu": [~36,[3,4]],
		"/(default)/(public)/(statics-support)/(cs)/prechod-plynovy-kotel-tepelne-cerpadlo": [~132,[3,30]],
		"/(default)/(public)/(statics-support)/(cs)/prechod-tuha-paliva-tepelne-cerpadlo": [~133,[3,30]],
		"/(default)/(public)/(statics-support)/(cs)/prechod-z-elektrokotle-na-tepelne-cerpadlo": [~134,[3,30]],
		"/(default)/(public)/(features-core)/(agreements)/predani-udaju-electree": [~39,[3,4]],
		"/(default)/(public)/(features-core)/(agreements)/predani-udaju-electree/odeslano": [~40,[3,4]],
		"/(default)/(public)/(features-core)/(agreements)/predani-udaju": [~37,[3,4]],
		"/(default)/(public)/(features-core)/(agreements)/predani-udaju/odeslano": [~38,[3,4]],
		"/(default)/(customer)/(redirects)/prihlaseni": [~33,[2]],
		"/test-translations": [142],
		"/woltadvisor": [143],
		"/(default)/(customer)/(redirects)/zakaznik/[...url]": [~34,[2]],
		"/(default)/(public)/(statics-support)/[photovoltaics_30_50=photovoltaics_30_50]": [~138,[3]],
		"/(default)/(public)/(statics-support)/[heat_pump_30_50=heat_pump_30_50]": [~137,[3]],
		"/(default)/(public)/(statics-support)/[extended_warranty=extended_warranty]": [~136,[3]],
		"/(default)/(public)/(statics-support)/(de)/[woltair_self_service=woltair_self_service]": [~135,[3]],
		"/(default)/(public)/(statics-core)/[praise_woltair=praise_woltair]": [~129,[3]],
		"/(default)/(public)/(statics-core)/[financing=financing]": [~126,[3,29]],
		"/(default)/(public)/(statics-core)/[documents=documents]": [~124,[3]],
		"/(default)/(public)/(statics-core)/[cooperation=cooperation]": [~123,[3]],
		"/(default)/(public)/(statics-core)/[contact=contact]": [~122,[3]],
		"/(default)/(public)/(statics-core)/[about_us=about_us]": [~118,[3]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]": [~103,[3,22]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]": [~82,[3,13]],
		"/(default)/(public)/(product-pages)/[gas_boilers=gas_boilers]": [~81,[3,12]],
		"/(default)/(public)/(product-pages)/[charging_station=charging_station]": [~78,[3,11]],
		"/(default)/(public)/(features-support)/[job_offers=job_offers]": [~73,[3]],
		"/(default)/(public)/(features-support)/[glossary=glossary]": [~71,[3]],
		"/(default)/(public)/(features-support)/[cms_preview=cms_preview]": [~69,[3]],
		"/(default)/(public)/(features-support)/[academy=academy]": [~66,[3,9]],
		"/(default)/(public)/(features-core)/[woltadvisor=woltadvisor]": [~65,[3,4]],
		"/(default)/(public)/(features-core)/[service=service]": [~56,[3,4]],
		"/(default)/(public)/(features-core)/[phone_consultation=phone_consultation]": [~55,[3,4]],
		"/(default)/(public)/(features-core)/[personal_meeting=personal_meeting]": [~52,[3,4,8]],
		"/(default)/(public)/(features-core)/[leave_contact=leave_contact]": [~51,[3,4,7]],
		"/(default)/(public)/(features-core)/[feedback=feedback]": [~44,[3,4,6]],
		"/(default)/(public)/(features-core)/[call_me=call_me]": [~41,[3,4]],
		"/(default)/(public)/(features-core)/[settings=settings]/errcheck": [~62,[3,4]],
		"/(default)/(public)/(statics-core)/[grants=grants]/kotlikove-dotace/[region]": [~128,[3]],
		"/(default)/(public)/(features-core)/[settings=settings]/ldcheck": [~63,[3,4]],
		"/(default)/(public)/(product-pages)/[marketplace=marketplace]/marketplaceExpertLead": [100,[3,17,19]],
		"/(default)/(public)/(product-pages)/[marketplace=marketplace]/marketplaceLeadDetail": [101,[3,17,20]],
		"/(default)/(public)/(product-pages)/[marketplace=marketplace]/marketplaceNeedsLead": [102,[3,17,21]],
		"/(default)/(public)/(features-core)/[call_me=call_me]/nzu-light": [~43,[3,4,5]],
		"/(default)/(public)/(features-core)/[service=service]/odeslano": [~58,[3,4]],
		"/(default)/(public)/(features-core)/[service=service]/platba": [~59,[3,4]],
		"/(default)/(public)/(features-core)/[service=service]/platba/zaplaceno": [~60,[3,4]],
		"/(default)/(public)/(features-core)/[settings=settings]/translations": [~64,[3,4]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]": [~112,[3,22,26]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[region=region]": [~110,[3,22]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[phone_consultation=phone_consultation]": [~109,[3,22,25]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[personal_meeting=personal_meeting]": [108,[3,22]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[offer=offer]": [~107,[3,22,24]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[leave_contact=leave_contact]": [~106,[3,22,23]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[financing=financing]": [~104,[3,22]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[woltadvisor=woltadvisor]": [~94,[3,13,16]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[simple_leadform=simple_leadform]": [~93,[3,13]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[region_list=region_list]": [~92,[3,13]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[region=region]": [~90,[3,13]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[phone_consultation=phone_consultation]": [~89,[3,13]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[personal_meeting=personal_meeting]": [88,[3,13]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[offer=offer]": [~87,[3,13,15]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[leave_contact=leave_contact]": [~86,[3,13,14]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[financing=financing]": [~84,[3,13]],
		"/(default)/(public)/(product-pages)/[charging_station=charging_station]/[grants=grants]": [~79,[3,11]],
		"/(default)/(public)/(features-core)/[settings=settings]/[privacy=privacy]": [~61,[3,4]],
		"/(default)/(public)/(features-core)/[personal_meeting=personal_meeting]/[paymemt=payment]": [~54,[3,4,8]],
		"/(default)/(public)/(features-core)/[personal_meeting=personal_meeting]/[new_payment=new_payment]": [~53,[3,4,8]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[woltair=woltair]": [~50,[3,4,6]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[thankyou=thankyou]": [~49,[3,4,6]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[operator=operator]": [~48,[3,4,6]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[filled=filled]": [47,[3,4,6]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[expert=expert]": [~46,[3,4,6]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[construction=construction]": [~45,[3,4,6]],
		"/(default)/(public)/(features-core)/[call_me=call_me]/[thank_you=thank_you]": [42,[3,4]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[woltadvisor=woltadvisor]/playground-combined": [~96,[3,13,16]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]/playground": [~113,[3,22,26,27]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[woltadvisor=woltadvisor]/playground": [~95,[3,13,16]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]/playground/energy-invoices": [114,[3,22,26,27]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]/playground/monthly-costs": [115,[3,22,26,27]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]/playground/query-short": [116,[3,22,26,27]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]/playground/sun-and-roof": [~117,[3,22,26,27]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[grants=grants]/[thank_you=thank_you]": [~105],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[grants=grants]/[thank_you=thank_you]": [~85],
		"/(default)/(public)/(product-pages)/[charging_station=charging_station]/[grants=grants]/[thank_you=thank_you]": [~80,[3,11]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[region=region]/[city]": [~111,[3,22]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[region=region]/[city]": [~91,[3,13]],
		"/(default)/(public)/(product-pages)/[marketplace=marketplace]/[heat_pump=heat_pump]/[...marketplacepath]/[slug]": [~98,[3,17]],
		"/(default)/(public)/(product-pages)/[marketplace=marketplace]/[photovoltaics=photovoltaics]/[...marketplacepath]": [~99,[3,17]],
		"/(default)/(public)/(statics-core)/[blog=blog]/[[paging=paging]]": [~119,[3,28]],
		"/(default)/(public)/(statics-core)/[grants=grants]/[dynamic_page_grants]": [~127,[3]],
		"/(default)/(public)/(statics-core)/[documents=documents]/[slug]": [~125,[3]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[dynamic_page_hvac]": [~83,[3,13]],
		"/(default)/(public)/(features-support)/[job_offers=job_offers]/[slug]": [~74,[3]],
		"/(default)/(public)/(features-support)/[glossary=glossary]/[slug]": [~72,[3]],
		"/(default)/(public)/(features-support)/[academy=academy]/[course]": [~67,[3,9]],
		"/(default)/(public)/(features-support)/[academy=academy]/[course]/[registered=registered]": [~68,[3,9]],
		"/(default)/(public)/(statics-core)/[blog=blog]/[category_or_slug]/[[paging=paging]]": [~120,[3,28]],
		"/(default)/(public)/(statics-core)/[blog=blog]/[category_or_slug]/[slug]": [~121],
		"/(default)/(public)/(features-core)/[service=service]/[device]/[[city]]": [~57,[3,4]],
		"/(default)/(public)/(features-support)/[expert_advice=expert_advice]/[...expert_advice_path]": [~70,[3]],
		"/(default)/(public)/(product-pages)/[marketplace=marketplace]/[...marketplacepath]": [~97,[3,17,18]],
		"/(default)/(public)/[...dynamic_page]": [~139,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';