import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const REGION_LIST_LOCALES = {
	WOLTAIR_CZ: null,
	WOLTAIR_PL: null,
	WOLTAIR_DE: 'standorte',
	WOLTAIR_IT: null
}

export const REGION_LIST = REGION_LIST_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return REGION_LIST === param
}) satisfies ParamMatcher
